import { Actions, Mutations } from "@/store/enums/StoreEnums";
import moment from "moment";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";


@Module
export default class AuthModule extends VuexModule {

    licenses = [];
    license: any | null = null;
    range: any | null = null;
    currDate: any = moment();
    lastRefresh: any = moment();
    lastClosedMonth: {
        month: number,
        year: number
    };
    taxes:{
        hasTax: boolean,
        dates: [],
        exceptions: []
    }
    filters: {
        // notDeparted: boolean,
        arrivalDate: any,
        origin: any,
    };

    loading: boolean;
    closingMonthAllowed: boolean;
    currentMonthisClosed: boolean;

    get currentLicenses() {
        return this.licenses;
    }

    get selectedLicense() {
        return this.license;
    }
    
    get selectedYearRange() {
        return this.range;
    }

    get currentTaxes() {
        return this.taxes;
    }

    get istatFilters() {
        return this.filters || {
            // notDeparted: false,
            arrivalDate: null,
            origin: null
        };
    }

    get istatLoading() {
        return this.loading;
    }

    get istatClosingMonthAllowed() {
        return this.closingMonthAllowed;
    }

    get istatCurrentMonthClosed() {
        return this.currentMonthisClosed;
    }

    get istatLastRefresh() {
        return this.lastRefresh;
    }

    get istatCurrDate() {
        return this.currDate;
    }

    get istatLastClosedMonth() {
        return this.lastClosedMonth;
    }


    @Mutation
    [Mutations.SET_LICENSES_MUTATION](licenses) {
        this.licenses = licenses;
        if (licenses.length) {
            this.license = licenses[0].id;
        }
    }
   
    @Mutation
    [Mutations.SET_TAXES_MUTATION](config) {
        this.taxes = config;
    }

    @Mutation
    [Mutations.SET_SELECTED_LICENSE_MUTATION](license) {
        this.license = license;
    }

    @Mutation
    [Mutations.SET_SELECTED_YEAR_RANGE_MUTATION](range) {
        this.range = range;
    }

    @Mutation
    [Mutations.SET_ISTAT_REFRESH_MUTATION](refresh) {
        this.lastRefresh = refresh;
    }

    @Mutation
    [Mutations.SET_ISTAT_LOADING_MUTATION](loading) {
        this.loading = loading;
    }

    @Mutation
    [Mutations.SET_ISTAT_CLOSINGMONTHALLOWED_MUTATION](closingMonthAllowed) {
        this.closingMonthAllowed = closingMonthAllowed;
    }

    @Mutation
    [Mutations.SET_ISTAT_CURRENTMONTHCLOSED_MUTATION](currentMonthisClosed) {
        this.currentMonthisClosed = currentMonthisClosed;
    }

    @Mutation
    [Mutations.SET_ISTAT_FILTERS_MUTATION](filters) {
        this.filters = { ...this.filters, ...filters };
    }

    @Mutation
    [Mutations.SET_ISTAT_CURRDATE_MUTATION](currDate) {
        this.currDate = currDate;
    }

    @Mutation
    [Mutations.SET_ISTAT_LASTCLOSEDMONTH_MUTATION](month) {
        this.lastClosedMonth = month;
    }

    @Action
    [Actions.SET_LICENSES_ACTION](licenses) {
        /**
         * @todo leggere l'elenco delle licenze attive  mediante chiamata a server 
         */
        this.context.commit(Mutations.SET_LICENSES_MUTATION, licenses);
    }
    
    @Action
    [Actions.SET_TAXES_ACTION](config) {
        /**
         * @todo leggere l'elenco delle licenze attive  mediante chiamata a server 
         */
        this.context.commit(Mutations.SET_TAXES_MUTATION, config);
    }

    @Action
    [Actions.SET_SELECTED_LICENSE_ACTION](license) {
        this.context.commit(Mutations.SET_SELECTED_LICENSE_MUTATION, license);
    }

    @Action
    [Actions.SET_SELECTED_YEAR_RANGE_ACTION](range) {
        this.context.commit(Mutations.SET_SELECTED_YEAR_RANGE_MUTATION, range);
    }

    @Action
    [Actions.SET_ISTAT_LOADING_ACTION](loading) {
        this.context.commit(Mutations.SET_ISTAT_LOADING_MUTATION, loading);
    }

    @Action
    [Actions.SET_ISTAT_CLOSINGMONTHALLOWED_ACTION](closingMonthAllowed) {
        this.context.commit(Mutations.SET_ISTAT_CLOSINGMONTHALLOWED_MUTATION, closingMonthAllowed);
    }

    @Action
    [Actions.SET_ISTAT_CURRENTMONTHCLOSED_ACTION](currentMonthClosed) {
        this.context.commit(Mutations.SET_ISTAT_CURRENTMONTHCLOSED_MUTATION, currentMonthClosed);
    }

    @Action
    [Actions.SET_ISTAT_REFRESH_ACTION](loading) {
        this.context.commit(Mutations.SET_ISTAT_REFRESH_MUTATION, loading);
    }

    @Action
    [Actions.SET_ISTAT_FILTERS_ACTION](filters) {
        this.context.commit(Mutations.SET_ISTAT_FILTERS_MUTATION, filters);
    }

    @Action
    [Actions.SET_ISTAT_CURRDATE_ACTION](minDate) {
        this.context.commit(Mutations.SET_ISTAT_CURRDATE_MUTATION, minDate);
    }

    @Action
    [Actions.SET_ISTAT_LASTCLOSEDMONTH_ACTION](month) {
        this.context.commit(Mutations.SET_ISTAT_LASTCLOSEDMONTH_MUTATION, month);
    }
}

