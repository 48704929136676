import { MANAGER_TYPES, ROLES } from "@/core/config/AppConfig";

export default {
    path: "admin/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "admin",
    meta: {
        guards: {
            types: [MANAGER_TYPES.admin, MANAGER_TYPES.provincia],
            roles: [ROLES.admin, ROLES.provincia],
        },
    },
    children: [
        {
            path: "",
            name: "admin.dashboard",
            component: () => import("@/views/DashboardAdmin.vue"),
        },
        {
            path: "users",
            name: "admin.users",
            component: () => import("@/views/users/UsersList.vue"),
        },
        {
            path: "users/:userId",
            name: "admin.users.detail",
            component: () => import("@/views/users/UserDetail.vue"),
        },
        {
            path: "accommodations",
            name: "admin.accommodations",
            component: () =>
                import("@/views/accommodations/admin/AccommodationsList.vue"),
        },
        {
            path: "accommodations/:accommodationId",
            name: "admin.accommodations.detail",
            component: () =>
                import("@/views/accommodations/admin/AccommodationDetail.vue"),
        },

        {
            path: "accommodations/licenses",
            name: "admin.accommodations.licenses",
            component: () =>
                import("@/views/accommodations/UnpairedLicensesList.vue"),
        },
        {
            path: "accommodations/license/:licenseId",
            name: "admin.accommodations.license.new",
            component: () =>
                import("@/views/accommodations/new/NewAccommodation.vue"),
        },
        {
            path: "accommodations/search",
            name: "admin.accommodations.search",
            component: () =>
                import("@/views/accommodations/SearchAccommodations.vue"),
        },
        {
            path: "rates",
            name: "admin.rates",
            component: () => import("@/views/rates/RatesList.vue"),
            children: [
                {
                    path: "new",
                    name: "admin.rates.create",
                    component: () => import("@/views/rates/CreatePeriod.vue"),
                },
                {
                    path: ":periodId",
                    name: "admin.rates.edit",
                    component: () => import("@/views/rates/CreatePeriod.vue"),
                },
            ],
        },
        {
            path: "enti",
            name: "admin.enti",
            component: () => import("@/views/licence/ManagersList.vue"),
            props: { type: "enti", detailRoute: "admin.enti.detail" },
            children: [
                {
                    path: "new",
                    name: "admin.enti.create",
                    props: { type: "enti" },
                    component: () =>
                        import("@/views/licence/ManagerCreate.vue"),
                },
            ],
        },
        {
            path: "enti/:managerDetailId",
            name: "admin.enti.detail",
            component: () => import("@/views/licence/ManagerDetail.vue"),
            children: [
                {
                    path: "users/",
                    name: "admin.enti.detail.user.create",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
                {
                    path: "users/:userId",
                    name: "admin.enti.detail.user.edit",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
            ],
        },
        {
            path: "testr",
            name: "admin.rates.test",
            component: () => import("@/views/rates/hotel/RateForm.vue"),
        },
        {
            path: "license",
            name: "admin.licenses.archive",
            component: () => import("@/views/licence/LicenceList.vue"),
        },
        {
            path: "license/:licenseId",
            name: "admin.licenses.archive.detail",
            component: () => import("@/views/licence/LicenseDetail.vue"),
            children: [
                {
                    path: "edit",
                    name: "admin.licenses.archive.detail.edit",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/edit-form/EditLicenseModal.vue"
                        ),
                },
                {
                    path: "suspend",
                    name: "admin.licenses.archive.detail.suspend",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/suspend/SuspendLicenseModal.vue"
                        ),
                },
                {
                    path: "close",
                    name: "admin.licenses.archive.detail.close",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/close/CloseLicenseModal.vue"
                        ),
                },
                {
                    path: "note",
                    name: "admin.licenses.archive.detail.note",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/add-note/AddNote.vue"
                        ),
                },
                {
                    path: "duplicate",
                    name: "admin.licenses.archive.detail.duplicate",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/duplicate/LicenseDuplicate.vue"
                        ),
                },
                {
                    path: "merge-istat/:licenseSourceId",
                    name: "admin.licenses.archive.detail.merge-istat",
                    component: () =>
                        import(
                            "@/views/licence/license-detail/action/merge-istat/MergeIstatModal.vue"
                        ),
                },
            ],
        },

        {
            path: "license/applications",
            name: "admin.licenses.applications",
            component: () => import("@/views/licence/ApplicationsList.vue"),
        },
        {
            path: "license/new_applications",
            name: "admin.licenses.new_applications",
            component: () => import("@/views/licence/NewApplicationsList.vue"),
        },
        {
            path: "license/new",
            name: "admin.licenses.new_app_wizard",
            component: () => import("@/views/licence/NewApplicationWizard.vue"),
        },
        {
            path: "license/new/:id",
            name: "admin.licenses.new_app_wizard",
            component: () => import("@/views/licence/NewApplicationWizard.vue"),
        },
        {
            path: "license/managers",
            name: "admin.licenses.managers",
            component: () => import("@/views/licence/ManagersList.vue"),
            props: { type: "hotel" },
            children: [
                {
                    path: "new",
                    name: "admin.licenses.managers.create",
                    props: { type: "hotel" },
                    component: () =>
                        import("@/views/licence/ManagerCreate.vue"),
                },
            ],
        },
        {
            path: "licence/managers/:managerDetailId",
            name: "admin.licenses.managers.detail",
            component: () => import("@/views/licence/ManagerDetail.vue"),
            children: [
                {
                    path: "users/",
                    name: "admin.licenses.managers.detail.user.create",
                    props: {
                        withPermissions: true,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
                {
                    path: "users/:userId",
                    name: "admin.licenses.managers.detail.user.edit",
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
            ],
        },
        {
            path: "licence/properties",
            name: "admin.licenses.properties",
            component: () => import("@/views/licence/PropertiesList.vue"),
        },
        {
            path: "citytax",
            name: "admin.citytax.summary",
            component: () => import("@/views/citytax/CitytaxSummary.vue"),
        },
        {
            path: "citytax/setup",
            name: "admin.citytax.setup",
            component: () => import("@/views/citytax/Setup.vue"),
        },
        {
            path: "citytax/setup/:id",
            name: "admin.citytax.new_setup",
            component: () => import("@/views/citytax/SetupForm.vue"),
        },
        {
            path: "citytax/rates",
            name: "admin.citytax.rates",
            component: () => import("@/views/citytax/Rates.vue"),
        },
        {
            path: "citytax/rates/:id",
            name: "admin.citytax.new_rate",
            component: () => import("@/views/citytax/RatesForm.vue"),
        },
        {
            path: "citytax/exemptions",
            name: "admin.citytax.exemptions",
            component: () => import("@/views/citytax/Exemptions.vue"),
        },
        {
            path: "citytax/exemptions/:id",
            name: "admin.citytax.new_exemption",
            component: () => import("@/views/citytax/ExemptionsForm.vue"),
        },
        {
            path: "citytax/cityrules",
            name: "admin.citytax.cityrules",
            component: () => import("@/views/citytax/CityRules.vue"),
        },
        {
            path: "citytax/citylist",
            name: "admin.citytax.citylist",
            component: () => import("@/views/citytax/Setup.vue"),
        },
        {
            path: "citytax/reports",
            name: "admin.citytax.reports",
            component: () => import("@/views/citytax/Reports.vue"),
        },
        {
            path: "settings",
            name: "admin.settings",
            component: () => import("@/views/DashboardAdmin.vue"),
        },
        {
            path: "settings/pms",
            name: "admin.settings.pms",
            component: () => import("@/views/settings/PmsList.vue"),
        },
        {
            path: "settings/pms/:id",
            name: "admin.settings.new_pms",
            component: () => import("@/views/settings/PmsForm.vue"),
        },
        {
            path: "settings/cities",
            name: "admin.settings.cities",
            component: () => import("@/views/settings/CitiesList.vue"),
        },
        {
            path: "settings/origins",
            name: "admin.settings.origins",
            component: () => import("@/views/settings/OriginsList.vue"),
        },
        {
            path: "settings/destinations",
            name: "admin.settings.destinations",
            component: () => import("@/views/settings/DestinationsList.vue"),
        },
        {
            path: "settings/accommodation_types",
            name: "admin.settings.accommodation_types",
            component: () =>
                import("@/views/settings/AccommodationTypesList.vue"),
        },
        {
            path: "settings/attractions",
            name: "admin.settings.attractions",
            component: () => import("@/views/settings/AttractionsList.vue"),
        },
        {
            path: "settings/attractions/:id",
            name: "admin.settings.new_attraction",
            component: () => import("@/views/settings/AttractionForm.vue"),
        },
        {
            path: "settings/roles",
            name: "admin.settings.roles",
            component: () => import("@/views/settings/RolesList.vue"),
        },
        {
            path: "settings/grants",
            name: "admin.settings.grants",
            component: () => import("@/views/settings/GrantsList.vue"),
        },

        {
            path: "analytics/guests",
            name: "admin.analytics.guest-nights",
            component: () => import("@/views/analytics/ReportGuests.vue"),
        },
        {
            path: "analytics/accommodations",
            name: "admin.analytics.accommodations",
            component: () =>
                import("@/views/analytics/ReportAccommodations.vue"),
        },
        {
            path: "analytics/origin",
            name: "admin.analytics.origin",
            component: () => import("@/views/analytics/ReportOrigin.vue"),
        },
        {
            path: "analytics/citytax",
            name: "admin.analytics.citytax",
            component: () => import("@/views/analytics/ReportCitytax.vue"),
        },
        {
            path: "knowledge_base",
            name: "admin.knowledge_base",
            component: () => import("@/views/KnowledgeBase.vue"),
        },        
    ],
};
